// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { isLocalhost } from '../app/utils/platform';

const siteUrl = isLocalhost()
  ? 'http://localhost:4200'
  : 'https://plus-admin-dev.extractly.ai';

export const environment = {
  production: false,
  apiEndpoint: 'https://api.plus-dev.extractly.ai/admin',
  amplify: {
    userPoolId: 'ap-southeast-2_Vk6ZgPkFN',
    userPoolClientId: '10jgt1lkuvcj59m8tovtcb3hog',
    // identityPoolId: 'ap-southeast-2:9157d900-5f07-4d1f-87db-805a9f3d54d0',
    identityPoolId: undefined,
    oauthDomain: 'qcadmindev.auth.ap-southeast-2.amazoncognito.com',
    redirectSignIn: [siteUrl],
    redirectSignOut: [siteUrl],
  },
};
