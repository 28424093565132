<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon icon="menu-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      >Extractly+ <span>admin</span></a
    >
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      class="user-action"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    >
      <nb-user
        [nbContextMenu]="userMenu"
        [name]="user?.name"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
