import { ResourcesConfig } from 'aws-amplify';
import { environment as env } from '../environments/environment';

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: env.amplify.userPoolId,
      userPoolClientId: env.amplify.userPoolClientId,
      identityPoolId: env.amplify.identityPoolId,
      loginWith: {
        email: true,
        oauth: {
          redirectSignIn: env.amplify.redirectSignIn,
          redirectSignOut: env.amplify.redirectSignOut,
          scopes: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          domain: env.amplify.oauthDomain,
          responseType: 'code',
        },
      },
      signUpVerificationMethod: 'code',
    },
  },
};

export default awsConfig;
