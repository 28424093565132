/**
 * Safely parse string to json without throwing error,
 * return null in case of error
 * @param data
 */
export function jsonParseSafe<T>(data: string): T | null {
  try {
    return JSON.parse(data);
  } catch (err) {
    console.error(err);
  }
  return null;
}
