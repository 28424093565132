<amplify-authenticator [hideSignUp]="true" [variation]="'modal'">
  <ng-template amplifySlot="sign-in-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
    Extractly+ Admin
    </h3>
  </ng-template>
  <router-outlet></router-outlet>
</amplify-authenticator>
