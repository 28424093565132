import { Injectable } from '@angular/core';
import { fetchUserAttributes, getCurrentUser } from '@aws-amplify/auth';
import { AuthEventData } from '@aws-amplify/ui';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { jsonParseSafe } from '../utils/json';
import { NGXLogger } from 'ngx-logger';
import { Hub } from 'aws-amplify/utils';
import { Router } from '@angular/router';
import { fetchAuthSession } from 'aws-amplify/auth';

export enum Role {
  SuperAdmin = 'SuperAdmin',
  FirmAdmin = 'FirmAdmin',
  FirmUser = 'FirmUser',
}

const initialAuthState: AuthState = {
  email: null,
  roles: [],
  error: null,
};

export interface AuthState {
  email?: string;
  roles?: Role[];
  error?: any;
}

export interface IGetUserAttributes {
  email: string;
  roles: Role[];
  firmId: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _state$: BehaviorSubject<AuthState> = new BehaviorSubject(
    initialAuthState
  );
  state$: Observable<AuthState> = this._state$.asObservable();
  roles: string[] = [];
  attributes: IGetUserAttributes;

  isSuperAdmin$ = this.state$.pipe(
    map((state) => state.roles?.includes(Role.SuperAdmin))
  );
  isFirmAdmin$ = this.getRoles().pipe(
    map((roles) => roles.includes(Role.FirmAdmin))
  );

  constructor(
    private authenticator: AuthenticatorService,
    private logger: NGXLogger,
    private router: Router
  ) {
    fetchAuthSession({ forceRefresh: true });
  }

  get state(): AuthState {
    return this._state$.getValue();
  }

  get isFirmAdmin(): boolean {
    return this.state.roles?.includes(Role.FirmAdmin);
  }

  get isSuperAdmin(): boolean {
    return this.state.roles?.includes(Role.SuperAdmin);
  }

  private setState(nextState: AuthState): void {
    const prevState = this.state;
    this._state$.next({ ...prevState, ...nextState });
  }

  public clearState(): void {
    this._state$.next(initialAuthState);
  }

  public setError(error: any): void {
    this.setState({ error });
  }

  init() {
    Hub.listen('auth', (data) => {
      this.logger.debug('auth data: ', data);
      const { channel, payload } = data;
      if (channel === 'auth') {
        switch (payload.event) {
          case 'signedIn':
            this.logger.debug('user signed in');
            this.fetchUserAttributes().subscribe();
            break;
          case 'signedOut':
            this.logger.debug('user signed out');
            this.attributes = undefined;
            this.clearState();
            this.router.navigate(['/']);
            break;
          default:
            break;
        }
      }
    });
    getCurrentUser()
      .then(() => {
        this.fetchUserAttributes().subscribe(() => {});
      })
      .catch(() => {});
  }

  async signOut(data?: AuthEventData): Promise<any> {
    return this.authenticator.signOut(data);
  }

  async getCurrentUser() {
    const user = await getCurrentUser();
    return user;
  }

  public isAuthenticated(): Observable<boolean> {
    return from(getCurrentUser()).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public fetchUserAttributes(): Observable<IGetUserAttributes> {
    if (this.attributes) {
      return of(this.attributes);
    }
    return from(fetchUserAttributes()).pipe(
      map((attributes) => ({
        email: attributes.email,
        roles: attributes?.['custom:roles']
          ? jsonParseSafe<Role[]>(attributes['custom:roles'])
          : [],
        firmId: attributes?.['custom:firmId']
          ? attributes['custom:firmId']
          : undefined,
      })),
      tap((attributes) => {
        this.attributes = attributes;
        this.setState(attributes);
      }),
      catchError(() => of(null))
    );
  }

  public getRoles(): Observable<string[]> {
    return this.state$.pipe(
      map((state) => state.roles),
      catchError(() => of([]))
    );
  }

  hasRole(role: Role): Observable<boolean> {
    return this.state$.pipe(
      map((state) => state.roles?.includes(role)),
      catchError(() => of(false))
    );
  }
}
